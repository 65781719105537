.band--report {
  padding-bottom: 4%;
  padding-top: 4%;

  .column {
    flex: 0 1 50%;
    text-align: center;
  }

  .title {
    color: $color-white;
  }

  .button {
    background-color: $color-tertiary;
    color: $color-white;

    &:hover {
      background-color: $color-secondary;
      color: $color-white;
    }
  }
}
