.menu--main {
  color: $color-white;
  display: flex;

  .menu {
    &__list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      @include mq($bp-medium) {
        flex-wrap: nowrap;
      }


      > .menu__item {
        flex: 1 1 100%;

        @include mq($bp-xsmall) {
          flex: 1 1 50%;
        }


        @include mq($bp-medium) {
          flex: 1 1 100%;
        }

      }

      .menu__list {
        display: block;
        margin-bottom: $gutter;

        @include mq($bp-medium) {
          margin-bottom: 0;
        }

        .menu__list {
          list-style: disc;
          margin-bottom: 5px;
          margin-left: $gutter;
        }
      }
    }


    &__link {
      color: $color-white;
      text-decoration: none;
      transition: 200ms all ease;

      &:hover {
        color: rgba($color-white, .8);
      }
    }
  }


  .title {
    @include font-size(25);
    color: $color-white;
  }
}
