// settings / includes
$mobile-menu: true;
$owl-carousel: true;

// breakpoints
$bp-xsmall: 480;
$bp-small: 600;
$bp-medium: 768;
$bp-large: 1024;
$bp-xlarge: 1280;
$bp-max: 1500;

// default colors
$color-black: rgb(0, 0, 0);
$color-grey-darker: lighten($color-black, 10%);
$color-grey-dark: lighten($color-black, 25%);
$color-grey: lighten($color-black, 50%);
$color-grey-light: lighten($color-black, 75%);
$color-grey-lighter: lighten($color-black, 92%);
$color-white: rgb(255, 255, 255);

$color-social-twitter: #35ccff;
$color-social-facebook: #03539e;
$color-social-linkedin: #217bc1;

$color-status-error: #e41b13;
$color-status-ok: #7ab317;
$color-status-info: #fff2af;

// colors
$color-body: darken($color-grey-lighter, 3);
$color-text: rgb(0, 0, 0);

$color-primary: #99045d;
$color-secondary: #d14314;
$color-tertiary: #6ab8df;


$pd-text-color: $color-text;
$pd-day-hover-bg: $color-secondary;
$pd-day-today-color: $color-text;
$pd-day-selected-bg: $color-primary;
$pd-day-selected-shadow: rgba(0, 0, 0, 0);

// typography
$font-default: 'PT Sans', sans-serif;
$font-title: 'Titillium Web', sans-serif;
$font-code: Monaco, Consolas, 'Lucida Console', monospace;


$font-lineheight: 1.5em;
$font-lineheight-alt: 1.1em;
$font-lineheight-title: 1.2em;

// margins
$gutter: 20px;
$margin-bottom: 20px;

$icon-calendar: ;
