.catalog {
  h2 {
    margin-bottom: 10px;
    text-align: center;
  }

  .product {
    background-color: $color-white;
    border: 1px solid $color-grey-lighter;
    box-sizing: border-box;
    margin-bottom: 20px;
    overflow: hidden;
    padding: 20px;
    position: relative;
    text-align: center;

    @include mq($bp-small) {
      float: left;
      margin-right: 2%;
      width: 49%;

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &:nth-of-type(2n-1) {
        clear: both;
      }
    }

    @include mq($bp-large) {
      margin-right: 2%;
      width: 32%;

      &:nth-of-type(2n) {
        margin-right: 2%;
      }

      &:nth-of-type(3n) {
        margin-right: 0;
      }

      &:nth-of-type(2n-1) {
        clear: none;
      }
    }

    .product__link {
      color: $color-text;
      display: block;
      text-decoration: none;

      &:hover {
        .product__image {
          opacity: .6;
        }
      }
    }

    .label {
      background-color: $color-primary;
      color: $color-white;
      font-weight: bold;
      height: 100px;
      line-height: 175px;
      position: absolute;
      right: 0;
      top: -75px;
      transform: rotate(45deg) translateX(60px) translateY(-15px);
      width: 120px;
      z-index: 2;

      &--new {
        background-color: $color-primary;
      }

      &--sale {
        background-color: $color-tertiary;
      }
    }

    .product__image {
      margin-bottom: 20px;
      position: relative;
      transition: opacity 200ms;

      @include mq($bp-small) {
        height: 180px;
      }

      @include mq($bp-large) {
        height: 240px;
      }


      .figure {
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        top: 0;
        width: 100%;

        @include mq($bp-small) {
          position: absolute;

          img {
            max-height: 180px;
          }
        }

        @include mq($bp-large) {
          img {
            max-height: 100%;
          }
        }
      }
    }

    .product__title {
      font-weight: bold;
      margin-bottom: 20px;
      min-height: 44px;
    }

    .product__price {
      @include font-size(18);
      color: $color-primary;
      font-weight: bold;

      &--old {
        @include font-size(12);
        color: $color-grey;
        display: inline-block;
        margin-right: 10px;
        text-decoration: line-through;
      }
    }
  }

  &--home .product {
    @include mq($bp-large) {
      margin-right: 1.3%;
      width: 24%;

      &:nth-of-type(2n) {
        margin-right: 1.3%;
      }

      &:nth-of-type(2n-1) {
        clear: none;
      }

      &:nth-of-type(4n) {
        margin-right: 0;
      }

      .product__image {
        height: 240px;
      }
    }
  }
}

.catalog-detail {

  .navigation {
    background-color: $color-grey-lighter;
    clear: both;
    margin-bottom: 20px;
    padding: 5px 15px;

    @include mq($bp-medium) {
      .navigation__back {
        display: block;
        width: 30%;
      }

      .navigation__previous {
        width: 15%;

      }

      .navigation__current {
        width: 40%;

      }

      .navigation__next {
        width: 15%;
      }
    }

    a {
      // @include transition-duration(200ms); FIX GULP?
      // @include transition-property(color); FIX GULP?
      color: $color-text;
      text-decoration: none;

      &:hover {
        color: $color-secondary;
      }
    }

    .button {
      top: 0;
    }

    .navigation__back {
      display: none;
      float: left;
      text-align: left;

      &::before {
        @include font-awesome($fa-var-caret-left);
      }
    }

    .navigation__previous {
      float: left;
      text-align: left;
      width: 30%;

      &::before {
        @include font-awesome($fa-var-caret-left);
      }
    }

    .navigation__current {
      float: left;
      text-align: center;
      width: 40%;
    }

    .navigation__next {
      float: left;
      text-align: right;
      width: 30%;

      &::after {
        @include font-awesome($fa-var-caret-right);
      }
    }
  }

  .product {

    @include mq($bp-xsmall) {
      .product__detail {
        float: left;
        margin-left: 2%;
        width: 58%;
      }

      .product__left {
        float: left;
        width: 40%;

        .product__image {
          margin-bottom: 20px;
        }

        .product__properties {
          .table {
            display: block;

            tr {
              display: block;
              margin-bottom: 5px;
            }

            td {
              display: block;

              &:nth-child(2) {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    @include mq($bp-small) {
      .product__left {
        .product__properties {
          .table {
            display: table;

            tr {
              display: table-row;
              margin-bottom: 0;
            }

            td {
              display: table-cell;

              &:nth-child(2) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    @include mq($bp-large) {
      .product__detail {
        float: left;
        margin-left: 1%;
        margin-right: 1%;
        width: 48%;
      }

      .product__left {
        float: left;
        width: 30%;
      }

      .products__related {
        float: left;
        width: 20%;
      }
    }

    .product__detail {
      margin-bottom: 30px;
    }

    .product__left {
      margin-bottom: 20px;

      .product__image {
        margin-bottom: 20px;

        &__slider {
          .figure {
            height: 350px;

            img {
              @include vertical-align;
              display: block;
              margin: 0 auto;
              max-width: 100%;
            }

            a {
              display: block;
              height: 100%;
              outline: none;
              position: relative;
            }
          }
        }

        &__thumbnails {
          .figure {
            box-sizing: border-box;
            clear: none;
            float: left;
            height: 80px;
            margin-bottom: 10px;
            padding: 2px;
            transition-duration: 200ms;
            transition-property: opacity border;

            &:hover {
              opacity: .6;
            }

            &:first-child {
              margin-left: 0;
            }

            img {
              @include vertical-align;
              display: block;
              margin: 0 auto;
              max-width: 100%;
            }
          }
        }
      }

      .product__properties {
        background-color: $color-grey-lighter;
        padding: 10px;

        .title--h3 {
          @include font-size(20);
        }
      }
    }

    .product__title {
      @include font-size(30);
    }

    .subtitle {
      font-weight: normal;
      margin-bottom: 20px;
    }


    .product__price {
      @include font-size(24);
      color: $color-primary;
      font-weight: bold;
      margin-bottom: 30px;

      .product__price--old {
        @include font-size(14);
        color: $color-grey;
        display: inline-block;
        margin-left: 10px;
        text-decoration: line-through;
      }

      .product__tax {
        @include font-size(14);
        color: $color-text;
        font-weight: normal;
      }
    }

    .product__variations {
      margin-bottom: 30px;

      .variation {
        float: left;
        margin-right: 2%;
        width: 49%;

        .variation__item {
          select {
            width: 100%;
          }
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }

    .product__numbers {
      margin-bottom: 30px;

      .product__number {
        float: left;
        margin-right: 2%;
        width: 49%;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }

    .product__stock {
      border: 1px solid $color-grey-light;
      border-width: 1px 0;
      margin-bottom: 20px;
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .product__amount {
      margin: 0 0 20px;

      .product__amount-input {
        border: 1px solid $color-grey-light;
        padding: 4px 10px;
        text-align: center;
        width: 30px;
      }
    }

    .product__button {
      border-bottom: 1px solid $color-grey-light;
      margin: 0 0 20px;
      padding-bottom: 20px;

      .btn {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    .title--h2 {
      @include font-size(20);
    }

    .products__related {
      clear: both;
      margin-top: 30px;

      @include mq($bp-large) {
        clear: none;
      }

      .title--products__related {
        @include font-size(18);
        margin-bottom: 10px;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .product--related {
      float: left;
      margin-bottom: 20px;
      margin-right: 2%;
      text-align: center;
      width: 49%;

      @include mq($bp-xsmall) {

        margin-right: 2%;
        width: 32%;

        &:nth-of-type(2n) {
          margin-right: 2%;
        }

        &:nth-of-type(3n) {
          margin-right: 0;
        }

        &:nth-of-type(2n-1) {
          clear: none;
        }

        &:nth-of-type(3n-2) {
          clear: both;
        }
      }

      @include mq($bp-large) {
        margin-right: 2%;
        width: 49%;

        &:nth-of-type(2n) {
          margin-right: 0;
        }

        &:nth-of-type(3n) {
          margin-right: 0;
        }

        &:nth-of-type(3n-2) {
          clear: none;
        }

        &:nth-of-type(2n-1) {
          clear: both;
          margin-right: 2%;
        }
      }

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &:nth-of-type(2n-1) {
        clear: both;
      }

      .product__link {
        color: $color-text;
        text-decoration: none;

        &:hover {
          .product__image {
            opacity: .6;
          }
        }
      }

      .product__image {
        float: none;
        height: 120px;
        margin-bottom: 5px;
        position: relative;
        transition-duration: 200ms;
        transition-property: opacity;
        width: auto;

        .figure {
          @include vertical-align;
          bottom: 0;
          left: 0;
          margin-bottom: 0;
          text-align: center;
          width: 100%;
        }
      }

      .product__title {
        @include font-size(13);
        font-weight: bold;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .product__price {
        @include font-size(13);
      }
    }

  }
}

.catalog__filter {
  background-color: $color-grey-lighter;
  margin-bottom: 30px;
  padding: 10px 15px;

  @include mq($bp-large) {
    background-color: transparent;
    float: left;
    margin-right: 2%;
    padding: 0;
    width: 25%;
  }

  .filters__toggle {
    @include font-size(20);
    font-weight: bold;
    position: relative;
    text-transform: uppercase;

    @include mq($bp-large) {
      display: none;
    }

    &::after {
      @include font-size(16);
      @include font-awesome($fa-var-chevron-down);
      position: absolute;
      right: 0;
    }
  }
}

.filter {
  margin: 0 0 20px;

  @include mq($bp-medium) {
    float: left;
    margin-right: 2%;
    width: 49%;

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  @include mq($bp-large) {
    float: none;
    margin-right: 0;
    width: auto;
  }

  &--current,
  &--category {
    border-bottom: 1px solid $color-grey-light;
    float: none;
    margin-right: 0;
    margin-top: 20px;
    padding-bottom: 20px;
    width: auto;

    @include mq($bp-large) {
      border-bottom-color: $color-grey-lighter;
      margin-top: 0;
    }

    .filter__item {
      .filter__remove {
        // @include transition-duration(200ms); FIX GULP?
        // @include transition-property(border-color, color); FIX GULP?
        background-color: $color-white;
        border: 1px solid $color-text;
        color: $color-text;
        display: inline-block;
        padding: 1px 6px;
        text-decoration: none;

        &::after {
          @include font-awesome($fa-var-times);
          // @include transition-duration(200ms); FIX GULP?
          // @include transition-property(border-color, color); FIX GULP?
          color: $color-grey-light;
          margin-left: 10px;
        }

        &:hover {
          border-color: $color-status-error;
          color: $color-status-error;

          &::after {
            color: $color-status-error;
          }
        }
      }
    }
  }

  &--category {
    .filter__item {
      .fa {
        margin-right: 5px;
      }

      .filter__link {
        color: $color-grey;
      }
    }
  }

  .filter__list {
    list-style: none;
  }

  .filter__title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .filter__item {
    line-height: 24px;

    .filter__link {
      // @include transition-duration(200ms); FIX GULP?
      // @include transition-property(color); FIX GULP?
      color: $color-text;
      text-decoration: none;

      &:hover {
        color: $color-primary;
      }
    }

    .filter__checkbox {
      margin-right: 5px;
      width: 15px;
    }

    &--selected {
      .filter__checkbox {
        color: $color-primary;
      }
    }
  }

}

.cart {
  margin-bottom: 30px;

  .navigation {
    clear: both;

    .navigation__link {
      @extend %button--primary;
      @extend %button;
      margin-bottom: 0;
      top: 0;
    }

    .navigation__back {
      float: left;

      .navigation__link {
        background-color: $color-grey;

        &:hover {
          background-color: $color-secondary;
        }
      }
    }

    .navigation__order {
      float: right;
    }

    .navigation__status {
      clear: both;
      color: $color-status-error;
      display: none;
      font-style: italic;
      line-height: 38px;
      text-align: right;

      @include mq($bp-large) {
        clear: none;
        text-align: center;
      }
    }
  }

  .products {
    .products__row {
      border-top: 1px dotted $color-grey-light;

      &--header {
        background-color: $color-grey-lighter;
        border-bottom: 1px solid $color-grey-light;
        font-weight: bold;
        padding: 15px 0;

        .products__column {
          padding-top: 0;
        }

      }

      &:nth-child(1),
      &:nth-child(2) {
        border-top: 0;
      }

      .products__column {
        box-sizing: border-box;
        float: left;
        min-height: 1px;
        padding: 0 10px 0 5px;

        @include mq($bp-small) {
          &--photo {
            display: block;
            width: 20%;
          }

          &--description {
            width: 25%;
          }

          &--amount {
            clear: none;
            width: 15%;
          }

          &--price {
            width: 15%;
          }

          &--subtotal {
            width: 15%;
          }

          &--actions {
            width: 10%;
          }
        }

        &--photo {
          width: 40%;

          .figure {
            margin-bottom: 0;
          }
        }

        &--description {
          width: 60%;

          .product__title {
            font-weight: bold;
          }
        }

        &--amount {
          clear: both;
          text-align: center;
          width: 30%;

          input {
            border: 1px solid $color-grey-light;
            padding: 4px 8px;
            text-align: center;
            width: 20px;
          }
        }

        &--price {
          text-align: right;
          width: 30%;
        }

        &--subtotal {
          font-weight: bold;
          text-align: right;
          width: 30%;
        }

        &--actions {
          text-align: right;
          width: 5%;
        }

      }

      &.product {
        padding-bottom: 10px;
        padding-top: 5px;

        @include mq($bp-small) {
          &--amount {
            padding-top: 20px;
          }

          &--price {
            padding-top: 20px;
          }

          &--subtotal {
            padding-top: 20px;
          }

          &--actions {
            padding-top: 20px;
          }
        }

        &--description {
          padding-top: 20px;
        }

      }

      .link--change-amount {
        border: 1px solid $color-grey-lighter;
        color: $color-grey;
        display: inline-block;
        min-width: 10px;
        padding: 0 5px;
        position: relative;
        text-align: center;
        text-decoration: none;
        top: 1px;

        &:hover {
          background-color: $color-secondary;
          border-color: $color-secondary;
          color: $color-white;
        }
      }

    }
  }

  .totals {
    background-color: $color-grey-lighter;
    border-top: 1px solid $color-grey;
    padding: 5px 0 10px;

    .totals__row {
      clear: both;
      line-height: 28px;

      @include mq($bp-small) {
        .totals__label {
          width: 75%;
        }

        .totals__value {
          width: 15%;
        }
      }

      .totals__label {
        float: left;
        text-align: right;
        width: 55%;
      }

      .totals__value {
        float: left;
        text-align: right;
        width: 35%;
      }

      &.totals__row--total {
        @include font-size(20);
        font-weight: bold;
      }
    }

  }

  &.cart--small {
    text-align: right;

    .cart__icon {
      color: $color-primary;
      float: left;
      margin-right: 10px;
    }

    .cart__title {
      min-width: 180px;
    }

    .cart__total {
      @include font-size(12);
    }

    .link {
      // @include transition-duration(200ms); FIX GULP?
      // @include transition-property(color); FIX GULP?
      color: $color-black;
      text-decoration: none;

      &:hover {
        color: $color-primary;
      }
    }

    &.cart--empty {
      .cart__icon {
        color: $color-grey-light;
      }

      .cart__title {
        @include font-size(12);
        color: $color-grey;
      }
    }
  }

}

.body--checkout {

  .title--h2 {
    margin-bottom: 30px;
  }

  .login {
    background-color: $color-grey-lighter;
    border: 1px solid $color-grey;
    box-sizing: border-box;
    margin: 0 0 20px;

    @include mq($bp-medium) {
      float: left;
      margin-right: 2%;
      min-height: 350px;
      width: 49%;
    }

    .title {
      background-color: $color-grey-light;
      padding: 10px 15px;
    }

    &:nth-of-type(2n) {
      background-color: $color-white;
      border: 1px solid $color-grey-light;
      margin-right: 0;

      .title {
        background-color: $color-grey-lighter;
      }
    }

    .login__content {
      padding: 20px;
    }

    .form {
      background-color: transparent;
      border: 0;
      margin-bottom: 0;
      padding: 0;
    }

    .link--alternate {
      float: right;
      font-style: italic;
      text-decoration: none;
    }

  }

  .checkout--check {
    .form {
      margin-top: 40px;
    }

    .form__column {
      min-height: 0;
    }
  }
}

.catalog__content {
  @include mq($bp-large) {
    float: right;
    width: 73%;
  }
}

.catalog__back {
  // @include transition-duration(200ms); FIX GULP?
  // @include transition-duration(background-color, color); FIX GULP?
  background-color: $color-grey-lighter;
  color: $color-text;
  display: none;
  margin: 0 0 20px;
  padding: 10px 15px;
  text-decoration: none;

  @include mq($bp-large) {
    display: block;
  }

  &:hover {
    background: $color-primary;
    color: $color-white;
  }

  .fa {
    font-size: 75%;
    margin-right: 5px;
  }
}

.description {
  background-color: $color-grey-lighter;
  margin-bottom: 20px;

  &__figure {
    display: none;
    float: left;
    margin: 0 20px 0 0;

    @include mq($bp-medium) {
      display: block;
    }
  }

  &__content {
    padding: 10px;

    @include mq($bp-large) {
      padding: 20px 30px;
    }
  }

  .title {
    @include font-size(20);
    margin: 0 0 20px;
  }

  &--filter {
    .description__content {
      @include mq($bp-large) {
        padding: 15px;
      }
    }
  }

  &--bottom {
    @include mq($bp-large) {
      float: left;
      margin-right: 2%;
      padding: 0;
      width: 25%;
    }
  }

}

.results {
  margin-bottom: 20px;

  &__count {
    float: left;
  }

  &__sort {
    float: right;
  }

  &__sort-label {
    display: none;

    @include mq($bp-xsmall) {
      display: inline;
    }
  }
}

.paging--catalog {
  clear: both;
}

.stock__state {
  font-weight: bold;

  &.stock__state--available {
    color: $color-primary;
  }

  &.stock__state--low {
    color: $color-tertiary;
  }

  &.stock__state--unavailable {
    color: $color-status-error;
  }
}

.action {
  &.action--delete {
    // @include transition-duration(200ms); FIX GULP?
    // @include transition-property(color); FIX GULP?
    color: $color-grey;

    &:hover {
      color: $color-status-error;
    }
  }
}
