.article--event {

  &__content {
    display: inline;
    float: left;
    width: 80%;

    @include mq($bp-small) {
      width: 85%;
    }
  }

  &__image-link,
  &__placeholder {
    display: block;
    float: left;
    margin-right: 2%;
    max-width: auto;
    text-align: center;
    width: auto;



    @include mq($bp-small) {
      width: 13%;
    }
  }

  &__placeholder {
    background: $color-grey-lighter;
    padding: 3% 0;

    i {
      @include font-size(28);
      color: $color-white;
    }
  }

  &__date {

    &--no-margin {
      margin-bottom: 0;
    }
  }
}

//
//  VOOR OPSOMMING IN SIDEBARS ZIE LIST.SCSS
//
