.band--story {
  background-color: $color-primary;
  color: $color-white;

  .title {
    color: $color-white;
  }

  .column {
    &:last-child {
      &::after {
        border: solid transparent;
        border-color: rgba($color-black, 0);
        border-right-color: $color-primary;
        border-width: 20px;
        content: '';
        height: 0;
        margin-top: -20px;
        pointer-events: none;
        position: absolute;
        right: 100%;
        top: 50%;
        width: 0;
      }
    }
  }
}
