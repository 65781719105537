.band--customer {
  color: $color-white;
  padding-bottom: 12.5%;
  padding-top: 12.5%;

  &::after {
    background: linear-gradient(to right, rgba($color-black, .55) 0%, rgba($color-black, 0) 100%);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .row {
    justify-content: flex-start;
  }

  .column {
    @include mq($bp-small) {
      max-width: 75%;
    }

    @include mq($bp-medium) {
      max-width: 50%;
    }


  }

  .title {
    color: $color-white;
  }

  .link {
    color: $color-white;
    text-decoration: underline;

    &:hover {
      color: rgba($color-white, .8);
    }
  }
}
