//  PARAGRAAF
.paragraph {
  margin-bottom: $margin-bottom;

  &--intro {
    @include base-font;
    @include font-size(16);
    color: $color-grey-dark;
  }

  &.paragraph--status {
    background-color: tint($color-status-info, 80%);
    border: 2px solid tint($color-status-info, 10%);
    color: shade($color-status-info, 25%);
    padding: 20px;

    .link {
      color: shade($color-status-info, 20%);

      &:hover {
        color: $color-secondary;
      }
    }
  }

  &.status--success {
    background-color: tint($color-status-ok, 80%);
    border: 2px solid shade($color-status-ok, 25%);
    color: shade($color-status-ok, 40%);
    padding: 20px;

    .link {
      color: tint($color-status-ok, 20%);

      &:hover {
        color: $color-secondary;
      }
    }
  }

  &.status--error {
    background-color: tint($color-status-error, 80%);
    border: 2px solid tint($color-status-error, 25%);
    color: $color-status-error;
    padding: 20px;

    .link {
      color: tint($color-status-error, 20%);

      &:hover {
        color: $color-secondary;
      }
    }
  }
}

sub {
  font-size: 80%;
  position: relative;
  top: 3px;
}

sup {
  font-size: 80%;
  position: relative;
  top: -3px;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}
