.table-wrapper {
  margin-bottom: $margin-bottom;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;

  @include mq($bp-large) {
    overflow-x: visible;
    overflow-y: visible;
  }

  .table {
    margin-bottom: 0;
    max-width: 99%;
  }
}

.table {
  color: $color-grey-dark;
  margin: 0 0 $margin-bottom;
  text-align: left;
  width: 100%;

  &__head {
    background-color: darken($color-grey-lighter, 3);
    font-weight: bold;
  }

  &__cell {
    border: 1px solid rgba($color-grey-light, .4);
    font-size: 80%;
    line-height: $font-lineheight-alt;
    padding: 3px 5px;
    text-align: left;

    @include mq($bp-small) {
      font-size: 90%;
      padding: 5px;
    }

    @include mq($bp-medium) {
      font-size: 100%;
      padding: 8px;
    }


    &--head,
    .table--row &:first-child {
      background-color: darken($color-grey-lighter, 3) !important;
      color: darken($color-grey-dark, 10%);
      font-weight: bold;
    }
  }

  &__row:nth-child(even) {
    background-color: lighten($color-grey-lighter, 6);
  }
}
