.band--topbar {
  .logo {
    left: 5px;
    position: absolute;
    top: 15px;

    @include mq($bp-medium) {
      left: 20px;
      top: 20px;
    }

    &::after {
      background-color: $color-white;
      content: '';
      display: block;
      height: 22vw;
      left: -13.5vw;
      position: absolute;
      top: -7.8vw;
      transform: rotate(51deg);
      width: 22vw;
      z-index: 1;

      @include mq($bp-medium) {
        height: 20vw;
        left: -11.85vw;
        width: 20vw;
      }


      @include mq($bp-max) {
        height: 300px;
        left: -177px;
        top: -117px;
        width: 300px;
      }

    }

    img {
      max-width: 27.5vw;
      position: relative;
      z-index: 2;

      @include mq($bp-medium) {
        max-width: 24vw;
      }


      @include mq($bp-max) {
        max-width: 361px;
      }

    }
  }

  .google_translate_element {
    width: 165px;
    position: absolute;
    top: 15px;
    left: 28vw;
    display:none;

    @include mq($bp-medium) {
      display:block;
    }

    @include mq($bp-max) {
      left: 400px;
    }
  }

  .topbar-links {
    @include font-size(12);
    display: flex;
    justify-content: flex-end;

    @include mq($bp-medium) {
      @include font-size(16);
    }

    @include mq($bp-large) {
      @include font-size(18);
    }



    &__link {
      border-left: 1px solid $color-grey-lighter;
      box-sizing: border-box;
      color: $color-text;
      display: block;
      min-width: 50px;
      padding: 10px 5px 5px;
      text-align: center;
      text-decoration: none;
      transition: 200ms all ease;

      @include mq($bp-medium) {
        min-width: 80px;
        padding: 15px 10px 8px;
      }

      @include mq($bp-large) {
        min-width: 100px;
        padding: $gutter $gutter $gutter / 2;
      }

      &:first-child {
        border-left: 0;
      }

      &:hover,
      .show-menu &.menu-trigger {
        background-color: $color-secondary;
        color: $color-white;

        .icon {
          color: $color-white;
        }
      }

      .icon {
        color: $color-secondary;
        display: block;
        font-size: 120%;
        transition: 200ms all ease;
      }
    }
  }
}
