.band {
  border-bottom: 1px solid $color-grey-lighter;
  flex: none;
  min-width: 300px;
  position: relative;

  &--bgcolor {
    background-color: tint($color-grey-lighter, 60%);
  }

  &--noborder {
    border: 0;
  }

  &--background-overlay {
    color: $color-white;

    &::after {
      background-color: rgba($color-primary, .7);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 1120px;
    padding: $gutter;
    position: relative;
    z-index: 2;

    &--full {
      max-width: inherit; //1560px;
      padding: 0;
    }

    &--centered {
      text-align: center;
    }

    &--double-padding {
      padding-bottom: $gutter * 2;
      padding-top: $gutter * 2;
    }
  }

  &__background {
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;

    &--left {
      padding-bottom: 50%;
      position: relative;

      @include mq($bp-small) {
        padding-bottom: 30%;
      }


      @include mq($bp-medium) {
        padding-bottom: 0;
        position: absolute;
        right: 50%;

        ~ .band__content .row {
          margin-left: 50%;
        }
      }
    }
  }
}

.row {
  box-sizing: border-box;

  @include mq($bp-small) {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    justify-content: center;
  }

  &--padding {
    padding: $gutter 0;
  }

  ~ .row {
    margin-top: $gutter;

    @include mq($bp-medium) {
      margin-top: $gutter * 2;
    }

  }
}

.column {
  box-sizing: border-box;
  position: relative;

  @include mq($bp-small) {
    flex: 0 1 100%;

    &--small {
      flex: 0 1 400px;

      &--left {
        padding-right: $gutter;

        @include mq($bp-large) {
          padding-right: $gutter * 2;
        }
      }

      &--right {
        padding-left: $gutter;

        @include mq($bp-large) {
          padding-left: $gutter * 2;
        }
      }
    }
  }

  .band--imageblock & {
    min-height: 25vw;

    @include mq(1600) {
      min-height: 400px;
    }
  }

  &--vcenter {
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3vw $gutter;
  }

  &__content {
    position: relative;
    z-index: 2;

    &--centered {
      text-align: center;
    }

    &--white {
      color: $color-white;
    }

    &--padding {
      @include mq($bp-small) {
        padding: 0 $gutter;
      }

      @include mq($bp-large) {
        padding: 0 ($gutter * 2);
      }
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__background {
    // background-color: $color-secondary;
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}

@import 'customer',
        'footer',
        'header',
        'main',
        'menu',
        'report',
        'story',
        'topbar';
