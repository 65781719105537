//STANDAARD
.list {
  list-style: disc;
  margin: 0 0 $margin-bottom 24px;

  &__item {
    .list {
      margin-bottom: 5px;
    }
  }

  // LIJST VOOR ARTIKELEN EN AGENDA
  &--articles,
  &--events {
    border-top: 1px solid $color-grey-lighter;
    line-height: $font-lineheight-alt;
    list-style: none;
    margin: 0 0 $margin-bottom;

    &__item {
      border-bottom: 1px solid $color-grey-lighter;
    }
  }

  &__link {
    display: block;
    padding: 5px 0;
    text-decoration: none;

    &--title {
      color: $color-primary;
      display: block;
      margin-bottom: 5px;
    }

    &:hover .list__link--title {
      color: $color-secondary;
    }

    &--date,
    &--location {
      @include font-size(12);
      color: $color-grey;
      display: block;
    }
  }

  &--custom-icon {
    list-style: none;
    margin-left: 0;

    .list__item {
      &::before {
        content: $fa-var-arrow-circle-o-right;
        font-family: FontAwesome;
        margin-right: 10px;
      }
    }
  }
}
