// // DO NOT EDIT DIRECTLY!
  //Generated by gulpfile.js/tasks/iconFont.js
  //from gulpfile.js/tasks/iconFont/template.scss

@font-face {
  font-family: 'icons';
  src: url('../fonts/icons.eot');
  src: url('../fonts/icons.eot?#iefix') format('embedded-opentype'),
       url('../fonts/icons.woff2') format('woff2'),
       url('../fonts/icons.woff') format('woff'),
       url('../fonts/icons.ttf') format('truetype'),
       url('../fonts/icons.svg#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icon($content: '') {
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: $content;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
  }
}

.icon {
  @include icon;
  line-height: 1;
}

// Save variable
$icon-bubble-chat-warning: "\EA01";

// Save mixin
@mixin icon--bubble-chat-warning {
  @include icon($icon-bubble-chat-warning);
}

// Expose as class
.icon.icon-bubble-chat-warning:before {
  content: $icon-bubble-chat-warning;
}

// Save variable
$icon-calendar: "\EA02";

// Save mixin
@mixin icon--calendar {
  @include icon($icon-calendar);
}

// Expose as class
.icon.icon-calendar:before {
  content: $icon-calendar;
}

// Save variable
$icon-contact: "\EA03";

// Save mixin
@mixin icon--contact {
  @include icon($icon-contact);
}

// Expose as class
.icon.icon-contact:before {
  content: $icon-contact;
}

// Save variable
$icon-faq: "\EA04";

// Save mixin
@mixin icon--faq {
  @include icon($icon-faq);
}

// Expose as class
.icon.icon-faq:before {
  content: $icon-faq;
}

// Save variable
$icon-house-block: "\EA05";

// Save mixin
@mixin icon--house-block {
  @include icon($icon-house-block);
}

// Expose as class
.icon.icon-house-block:before {
  content: $icon-house-block;
}

// Save variable
$icon-house-search: "\EA06";

// Save mixin
@mixin icon--house-search {
  @include icon($icon-house-search);
}

// Expose as class
.icon.icon-house-search:before {
  content: $icon-house-search;
}

// Save variable
$icon-logo: "\EA07";

// Save mixin
@mixin icon--logo {
  @include icon($icon-logo);
}

// Expose as class
.icon.icon-logo:before {
  content: $icon-logo;
}

// Save variable
$icon-menu: "\EA08";

// Save mixin
@mixin icon--menu {
  @include icon($icon-menu);
}

// Expose as class
.icon.icon-menu:before {
  content: $icon-menu;
}

// Save variable
$icon-person-edit: "\EA09";

// Save mixin
@mixin icon--person-edit {
  @include icon($icon-person-edit);
}

// Expose as class
.icon.icon-person-edit:before {
  content: $icon-person-edit;
}

// Save variable
$icon-search: "\EA0A";

// Save mixin
@mixin icon--search {
  @include icon($icon-search);
}

// Expose as class
.icon.icon-search:before {
  content: $icon-search;
}

// Save variable
$icon-wallet: "\EA0B";

// Save mixin
@mixin icon--wallet {
  @include icon($icon-wallet);
}

// Expose as class
.icon.icon-wallet:before {
  content: $icon-wallet;
}

// Save variable
$icon-wrench-screwdriver: "\EA0C";

// Save mixin
@mixin icon--wrench-screwdriver {
  @include icon($icon-wrench-screwdriver);
}

// Expose as class
.icon.icon-wrench-screwdriver:before {
  content: $icon-wrench-screwdriver;
}


