.faq {
  margin-bottom: $gutter * 2;

  &__item {
    position: relative;

    ~ .title {
      margin-top: $gutter * 2;
    }
  }

  &__question {
    cursor: pointer;
    transition: 200ms all ease;

    &:hover {
      color: $color-secondary;
    }

    &::before {
      color: $color-secondary;
      content: $fa-var-chevron-right;
      display: inline-block;
      font-family: FontAwesome;
      margin-right: 15px;
      // min-width: 25px;
      transition: 200ms all ease;
    }
  }

  &__answer {
    display: none;
    margin: 0 0 $gutter 30px;
  }

  &__item--active {
    .faq__question {
      color: $color-secondary;
      font-weight: bold;

      &::before {
        transform: rotate(90deg);
      }
    }

    .faq__answer {
      display: block;
    }
  }
}
