//  TITLES
.title {
  @include title-font();
  color: $color-primary;
  margin-bottom: .2em;

  .link {
    text-decoration: none;
  }

  &--h1 {
    @include font-size(36);
    margin-bottom: $margin-bottom;

    @include mq($bp-medium) {
      @include font-size(46);
    }

    @include mq($bp-large) {
      @include font-size(56);
    }


  }

  &--h2 {
    @include font-size(24);
    margin-bottom: $margin-bottom;

    @include mq($bp-medium) {
      @include font-size(28);
    }

    @include mq($bp-large) {
      @include font-size(34);
    }


  }

  &--h3 {
    @include font-size(18);

    @include mq($bp-medium) {
      @include font-size(20);
    }

    @include mq($bp-large) {
      @include font-size(24);
    }


  }

  &--h4 {
    @include font-size(18);
    margin-bottom: $gutter / 2;

    @include mq($bp-medium) {
      @include font-size(20);
    }

  }

  &--h5,
  &--h6 {
    @include font-size(16);
  }
}
