.video-player {
  clear: both;
  display: block;
  float: left;
  margin: 0 0 $margin-bottom;
  width: 100%;

  &__wrapper {
    height: 0;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 0 56.25%; // 16:9 ratio
    position: relative;
    width: 100%;

    iframe,
    object,
    embed {
      height: 100% !important;
      left: 0;
      position: absolute;
      top: 0;
      width: 100% !important;
    }
  }
}
