.sitemap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;


  > .sitemap__item {
    flex: 1 1 100%;
    margin-bottom: $gutter;

    @include mq($bp-xsmall) {
      flex: 1 1 50%;
    }

    > .link {
      @include font-size(25);
      color: $color-primary;
      font-family: $font-title;
      font-weight: bold;
    }

  }

  .sitemap--sub {
    display: block;
    margin-bottom: $gutter;

    @include mq($bp-medium) {
      margin-bottom: 0;
    }

    .sitemap--sub {
      color: $color-secondary;
      list-style: disc;
      margin-bottom: 5px;
      margin-left: $gutter;
    }
  }
}
