.figure {
  clear: both;
  margin: 0 auto $margin-bottom;
  max-width: 100%;

  //  UITLIJNING
  @include mq($bp-small) {
    &--left,
    &--right {
      display: inline;
      max-width: 49%;
    }

    &--left {
      float: left;
      margin: 0 2% $margin-bottom 0;
    }

    &--right {
      float: right;
      margin: 0 0 $margin-bottom 2%;
    }

    &--no-margin {
      margin: 0 0 $margin-bottom;
    }
  }

  &--left {
    clear: left;
    float: left;
    margin-right: 2%;
  }

  &--right {
    clear: right;
    float: right;
    margin-left: 2%;
  }


  &--left + &--right {
    margin-left: 0;
    margin-right: 0;

    + * {
      clear: both;
    }
  }

  &--right + &--left {
    clear: both;
  }

  &--left + *:not(.title),
  &--left + .title + * {
    @include clearfix();
  }

  &--right + *:not(.title),
  &--right + .title + * {
    @include clearfix();
  }

  // IMG
  &__image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  //  ONDERSCHRIFT
  &__caption {
    @include base-font();
    @include font-size(11);
    color: lighten($color-text, 25);
    display: block;
    margin: 6px auto 0;
    text-align: center;

    @include mq($bp-small) {
      @include font-size(13);
    }
  }
}
