.band--main {
  .body--home & {
    border-bottom: 0;

    .row {
      display: flex;
      flex-direction: column;

      @include mq($bp-medium) {
        flex-direction: row;
      }

    }

    .column {
      &:first-child {
        order: 1;

        @include mq($bp-medium) {
          order: initial;
        }
      }

      &:last-child {
        order: 0;

        @include mq($bp-medium) {
          order: initial;
        }
      }

    }
  }

  .column {
    &:first-child {
      .column__content--padding {
        padding-left: 0;
      }
    }
  }
}
