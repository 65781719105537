.popupbanner {
	@include clearfix();
	// background: $color-white;
	bottom: 0;
	display: none;
	// height: 400px;
	left: 50%;
	margin-left: -300px;
	max-height: 80vh;
	position: fixed;
	right: 0;
	top: 20vh;
	width: 600px;
	z-index: 99999;
	overflow: hidden;

	@include mq(640) {
		.showbanner & {
			display: block;
		}
	}

	&__wrapper {
		display: inline;
		float: left;
		margin: 0;
		position: relative;
		width: 100%;
	}

	&__close {
		@include font-size(24);
		text-decoration: none;
		background: $color-secondary;
		color: $color-white;
		height: 32px;
		line-height: 28px;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		width: 32px;

		&:hover {
			background: $color-white;
			color: $color-secondary;
		}
	}

	&__content {
		@include clearfix();
		background: $color-white !important;

		img, .figure {
			max-width: 100%;
			display: block;
			margin: 0;
		}

		.figure__caption {
			display: none;
		}

		.title{
			padding: 10px 20px 0 !important;
			margin: 0 !important;
		}

		.paragraph,
		.list {
			padding: 10px 20px;
			margin-bottom: 10px !important;
		}

		.button {
			top: 5px !important;
		}

	}

	&--bg {
		background: $color-black;
		bottom: 0;
		display: none;
		left: 0;
		opacity: .8;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 99998;

		@include mq(640) {
			.showbanner & {
				display: block;
			}
		}
	}
}
