.band--menu {
  background-color: $color-secondary;
  color: $color-white;
  max-height: 0;
  overflow: hidden;
  position: relative;

  .show-menu & {
    max-height: none;

    @include mq($bp-xsmall) {
      padding-top: 20px;
    }

    @include mq($bp-small) {
      padding-top: 40px;
    }

    @include mq($bp-medium) {
      padding-top: 60px;
    }
  }
}
