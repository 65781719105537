html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  tap-highlight-color: transparentize($color-black, .9);
  text-size-adjust: 100%;
}

body {
  @include base-font();
  @include font-size(15);
  background: $color-body;
  color: $color-text;
  line-height: 1.8;
  margin: 0;
  overflow-x: hidden;

  @include mq($bp-medium) {
    @include font-size(16.5);
  }

  @include mq($bp-large) {
    @include font-size(18);
  }

}

@import 'layout';
@import 'bands/';
