.band--header {
  .band__background {
    padding-bottom: 20%;
    position: relative;
  }

  .show-menu & {
    display: none;
  }
}
