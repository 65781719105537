.site {
  background: $color-white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1502px;
  min-height: 100vh;
  min-width: 300px;
  overflow: hidden;
  position: relative;

  @include mq(1502) {
    border-left: 1px solid shade($color-body, 5);
    border-right: 1px solid shade($color-body, 5);
  }
}
