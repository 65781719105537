.menu--icons {
  display: flex;
  flex-wrap: wrap;

  .menu {
    &__item {
      @include font-size(14);
      align-items: center;
      box-sizing: border-box;
      color: $color-secondary;
      display: flex;
      flex: 0 0 50%;
      flex-direction: column;
      justify-content: center;
      max-width: 50%;
      min-height: 140px;
      overflow: hidden;
      padding: 20px 10px;
      position: relative;
      text-align: center;
      text-decoration: none;
      transition: 200ms all ease;

      @include mq($bp-large) {
        @include font-size(20);
        flex: 0 0 33.33%;
        max-width: 33.33%;
        min-height: 200px;
      }


      &::after {
        background-color: $color-white;
        content: '';
        display: block;
        height: 40px;
        left: -20px;
        position: absolute;
        top: -20px;
        transform: rotate(45deg);
        width: 40px;
      }

      &:hover {
        background-color: $color-secondary;
        color: $color-white;
      }

      .icon {
        @include font-size(35);
        display: block;
        margin-bottom: $gutter;

        @include mq($bp-large) {
          @include font-size(60);
        }

      }
    }
  }
}
