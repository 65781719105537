.menu--footer {
  .menu__link {
    color: $color-secondary;
    text-decoration: none;
    transition: 200ms all ease;

    &:hover {
      color: $color-primary;
    }
  }
}
