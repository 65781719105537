.employees {
  .employee {
    box-sizing: border-box;
    clear: both;
    display: none;
    flex-wrap: wrap;
    margin-bottom: $gutter;
    margin-right: $gutter;

    &:first-child {
      display: flex;
    }

    &__quote {
      background-color: $color-tertiary;
      border-radius: 5px;
      box-sizing: border-box;
      color: $color-white;
      flex: 0 0 100%;
      margin-bottom: $gutter * 2;
      max-width: 100%;
      padding: 20px;
      position: relative;

      &::after {
        border-color: $color-tertiary transparent;
        border-style: solid;
        border-width: 40px 60px 0 0;
        bottom: -20px;
        content: '';
        display: block;
        left: 40px;
        position: absolute;
        width: 0;
      }
    }

    &__bottom {
      display: flex;
    }

    &__image {
      flex: 0 1 120px;
      float: left;
      margin-right: 10px;

      @include mq($bp-small) {
        flex: 0 1 80px;
      }

      @include mq($bp-medium) {
        flex: 0 1 120px;
      }



      img {
        border-radius: 5px;
        display: block;
      }
    }

    &__name {
      font-weight: bold;
    }

    .fa {
      color: $color-secondary;
      font-size: 110%;
      margin-right: 5px;

      &:hover {
        color: $color-primary;
      }
    }
  }

  .owl-dots {
    margin: 0 auto;
    text-align: center;

    .owl-dot {
      border: 2px solid $color-secondary;
      border-radius: 50%;
      display: inline-block;
      height: 14px;
      margin: 0 5px;
      width: 14px;

      &.active {
        background-color: $color-primary;
        border-color: $color-primary;
      }
    }
  }
}
