.band--search {
  background: lighten($color-grey-lighter, 5);
  height: 50px;
  overflow: hidden;

  form {
    display: block;
    height: 50px;
    margin: 0 auto;
    max-width: 600px;
    padding: 0;
  }

  .search {
    background: $color-white;
    border: 1px solid $color-grey-light;
    display: block;
    height: 28px;
    margin: 10px auto;
    max-width: 800px;
    overflow: hidden;
    position: relative;

    input {
      border: 0;
      display: inline;
      float: left;
      outline: none;

      &.search__input {
        @include font-size(14);
        background: $color-white;
        border: 0;
        color: $color-grey-dark;
        font-family: $font-default;
        height: 24px;
        margin: 0;
        outline: none;
        padding: 2px 2px 2px 5px;
        width: 100%;

        @include mq($bp-small) {
          @include font-size(16);
        }

        .lt-ie9 & {
          line-height: 24px;
        }
      }
    }

    .search__button {
      @include font-size(14);
      background: $color-white;
      border: 0;
      color: $color-grey;
      cursor: pointer;
      height: 26px;
      margin: 0;
      opacity: .5;
      outline: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 28px;

      &:hover,
      &:focus {
        color: $color-grey;
        opacity: 1;
      }

      .lt-ie9 & {
        top: 2px;
      }
    }
  }
}
