.breadcrumb {
  align-items: center;
  display: flex;
  font-size: 80%;

  @include mq($bp-small) {
    font-size: 100%;
  }


  &__item {
    display: inline-block;
    margin-right: 5px;

    @include mq($bp-small) {
      margin-right: 10px;
    }


    &::after {
      content: $fa-var-angle-right;
      display: inline-block;
      font-family: FontAwesome;
      margin-left: 5px;

      @include mq($bp-small) {
        margin-left: 10px;
      }

    }

    &:first-child::after,
    &:last-child::after {
      display: none;
    }

    &:first-child {
      max-width: 60px;

      @include mq($bp-small) {
        max-width: none;
      }

    }
  }

  &__link {
    color: $color-text;
    text-decoration: none;
  }

  img {
    display: block;
    max-width: 100%;
  }
}
