.band--footer {
  @include font-size(16);

  .row {
    clear: both;

    @include mq($bp-small) {
      flex-wrap: wrap;
    }

    @include mq($bp-medium) {
      flex-wrap: nowrap;
    }
  }

  .column {
    margin-bottom: $gutter;

    @include mq($bp-small) {
      flex: 0 1 50%;
    }


    @include mq($bp-medium) {
      margin-bottom: 0;
    }

  }

  .figure--right {
    @include mq($bp-medium, max) {
      float: none;
      margin: 0 auto;
      text-align: center;
    }

    @include mq($bp-medium) {
      margin-right: 150px;
    }


  }

  .link {
    color: $color-secondary;

    &:hover {
      color: $color-primary;
    }
  }
}
