.menu--legal {
  @include font-size(14);
  color: $color-secondary;

  .menu {
    &__item {
      display: inline-block;
      margin-left: 10px;

      &::before {
        content: '|';
        margin-right: 10px;
      }

      &:first-child::before {
        display: none;
      }
    }

    &__link {
      color: $color-secondary;
      text-decoration: none;
      transition: 200ms all ease;

      &:hover {
        color: $color-primary;
      }
    }
  }
}
