.repairs {
  counter-reset: repairs;
  display: flex;
  padding-top: 5px;

  &__progress {
    @include font-size(16);
    display: none;
    flex: 0 0 25%;

    @include mq($bp-medium) {
      display: block;
    }
  }

  &__flow {
    max-width: 100%;
  }


  .progress {
    box-sizing: border-box;

    &__step {
      align-items: baseline;
      border-left: 1px solid $color-grey-light;
      cursor: pointer;
      display: flex;
      margin-left: 5px;
      margin-top: -4px;
      padding-bottom: 10px;
      position: relative;

      &::before {
        $size: 15px;
        background-color: $color-white;
        border: 1px solid $color-grey-light;
        border-radius: 50%;
        content: '';
        display: block;
        flex: 0 0 $size;
        height: $size;
        left: -9px;
        position: relative;
        top: 2px;
        width: $size;
      }

      &--active {
        border-left-color: transparent;

        &::before {
          background-color: $color-primary;
          border: 1px solid $color-primary;
        }

        ~ .progress__step {
          display: none;
        }
      }

    }
  }

  .question {
    align-items: baseline;
    border-left: 2px solid $color-grey-light;
    counter-increment: repairs;
    margin-left: 5px;
    padding-bottom: $gutter * 4;
    padding-left: 30px;
    position: relative;

    @include mq($bp-medium) {
      margin-top: -5px;
    }


    &::before {
      @include font-size(14);
      $size: 30px;
      align-items: center;
      background-color: $color-white;
      border: 2px solid $color-grey-light;
      border-radius: 50%;
      color: $color-grey-light;
      content: counter(repairs);
      display: flex;
      flex: 0 0 $size;
      height: $size;
      justify-content: center;
      left: -18px;
      position: absolute;
      top: 0;
      width: $size;

      @include mq($bp-medium) {
        top: 5px;
      }

    }

    &--active {
      border-left-color: transparent;

      &::before {
        border-color: $color-primary;
        color: $color-primary;
      }

      ~ .question {
        display: none;
      }
    }
  }

  .repairs-form {
    input {
      @include font-size(18);
      border: 1px solid $color-grey-light;
      border-radius: 4px;
      box-sizing: border-box;
      font-family: $font-default;
      padding: 6px 10px;

      &[type='radio'],
      &[type='checkbox'] {
        width: auto;
      }
    }

    select {
      @include font-size(18);
      background: transparent;
      border: 1px solid $color-grey-light;
      border-radius: 4px;
      color: $color-text;
      font-family: $font-default;
      height: 39px;
      padding: 6px 10px;
      width: 100%;

      @include mq($bp-medium) {
        max-width: 275px;
      }


      option {
        @include font-size(18);
        color: $color-text;
      }
    }

    textarea {
      @include font-size(18);
      border: 1px solid $color-grey-light;
      border-radius: 4px;
      box-sizing: border-box;
      font-family: $font-default;
      min-height: 150px;
      padding: 5px;
    }

    &--address {
      @include mq($bp-medium) {
        display: flex;
      }


      .repairs-form__item {
        margin-bottom: 10px;
        margin-right: $gutter;

        @include mq($bp-medium) {
          margin-bottom: 0;
          margin-right: $gutter * 2;
        }

      }

      input {
        max-width: 180px;

        @include mq($bp-medium) {
          max-width: 90px;
        }
      }

      .button {
        font-weight: bold;
        margin-bottom: 0;
        max-width: 200px;
        padding: 10px 20px;
        width: 100%;

        @include mq($bp-medium) {
          padding: 8px 20px;
          width: auto;

          &__text {
            display: none;
          }
        }


        .fa {
          margin-left: 5px;
        }
      }
    }

    &--icons {
      display: flex;
      flex-wrap: wrap;

      .repairs-form__item {
        box-sizing: border-box;
        flex: 0 0 50%;
        justify-content: flex-start;
        margin-bottom: $gutter;
        padding: 0 10px;
        text-align: center;

        @include mq($bp-medium) {
          flex: 0 0 33.33%;
          margin-bottom: 0;
        }

      }

      .repairs-form__value {

        .repairs-form__icon {
          $size: 75px;
          align-items: center;
          background-color: $color-grey-light;
          border-radius: 100%;
          cursor: pointer;
          display: inline-flex;
          height: $size;
          justify-content: center;
          transition: 200ms all ease;
          width: $size;

          @include mq($bp-medium) {
            $size: 125px;
            height: $size;
            width: $size;
          }

          img {
            max-width: 50%;

            @include mq($bp-medium) {
              max-width: 100%;
            }

          }

          &:hover {
            background-color: $color-secondary;
          }
        }

        input {
          display: none;

          &:checked ~ .repairs-form__icon {
            background-color: $color-secondary;
          }

          &:disabled ~ .repairs-form__icon {
            pointer-events: none;
          }
        }
      }
    }

    &.appointment-form {
      @include mq($bp-medium) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > * {
          flex: 0 1 47.5%;
        }
      }

      .title {
        margin-bottom: $gutter;
      }

      input,
      select,
      textarea,
      .button {
        max-width: calc(100% - 40px);
        width: 100%;
      }

      .button {
        padding: 10px 20px;
        width: 100%;

        // @include mq($bp-medium) {
        //   max-width: calc(47.5% - 40px);
        // }
      }

      .appointment-form {
        &__item {
          display: block;
          margin-bottom: $gutter;
        }

        &__value {
          &--calendar {
            &::after {
              color: $color-secondary;
              content: $icon-calendar;
              font-family: icons;
              font-size: 125%;
              line-height: 1;
              vertical-align: middle;
            }
          }
        }
      }
    }

    &--address .repairs-form__value,
    .appointment-form__value {

      &::after {
        content: '';
        display: inline-block;
        min-width: 20px;
      }

      &.status--ok {
        &::after {
          color: $color-status-ok;
          content: $fa-var-check;
          font-family: FontAwesome;
        }
      }

      &.status--error {
        &::after {
          color: $color-status-error;
          content: $fa-var-close;
          font-family: FontAwesome;
        }

        input {
          box-shadow: 0 3px 0 0 rgba($color-status-error, 1);
        }
      }

      &.status--unknown {
        &::after {
          color: $color-grey-light;
          content: $fa-var-ellipsis-h;
          font-family: FontAwesome;
        }
      }
    }

    &__addition-help {
      font-size: 80%;
      margin-top: 5px;

      ul {
        list-style: inherit;
        margin-left: 18px;
      }

      &-number {
        color: $color-grey;
      }
    }
  }

  .status--message {
    color: $color-status-error;
    margin-top: 10px;
  }
}

.pika-lendar {
  .pika-button {
    text-align: center;
  }
}

[ng-controller="plannedMaintenanceController"],
[ng-controller="comfortPlusHouseController"] {

  hr {
    border: 1px solid $color-grey-light;
    margin: 20px 0;
  }

  dl {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
    max-width: 400px;

    dd,
    dt {
      flex: 1 1 50%;
    }

    dt {
      flex: 1 1 30%;
      font-weight: bold;
      min-width: 150px;
    }
  }

  ul {
    margin-left: 20px;

    li {
      list-style: disc;
    }
  }
}
