%button {
  @include font-size(14);
  @include title-font();
  background-color: $color-secondary;
  border: 0;
  border-radius: 4px;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  margin-bottom: $margin-bottom;
  padding: 5px 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .2s ease;
  white-space: nowrap;

  &:hover {
    background: $color-primary;
    color: $color-white;
  }
}

%button--primary {
  background: $color-primary;
  color: $color-white;

  &:hover {
    background: $color-secondary;
    color: $color-white;
  }
}

.link {
  color: $color-primary;
  transition: 200ms all ease;

  &:hover {
    color: $color-secondary;
  }

  .band--main & {
    color: $color-secondary;
    text-decoration: none;

    &:hover {
      color: $color-primary;
    }
  }
}

.search-result__hit {
  background: $color-status-info;
  padding: 3px;
  text-decoration: none;
}

// BUTTON

.button {
  @extend %button;

  @include mq($bp-small) {
    @include font-size(18);
    padding: 8px 10px;
  }

  &.button--primary {
    @extend %button--primary;
  }

  &.button--right {
    float: right;
  }

  &.button--hidden {
    display: none;
  }
}

// TOGGLE MENU
@if $mobile-menu == true {
  .toggle-menu {
    @include font-size(20);
    @include title-font;
    background: $color-primary;
    color: $color-white;
    height: 42px;
    line-height: 42px;
    padding: 0;
    position: absolute;
    right: 10px;
    text-align: center;
    text-decoration: none;
    top: 10px;
    width: 42px;

    @include mq($bp-small) {
      display: none;
    }

    &:hover {
      background: $color-secondary;
    }
  }
}
