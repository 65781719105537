.contact-block-holder {

  @include mq($bp-small) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

  }

}

.contact-block {
  box-sizing: border-box;
  margin-bottom: 40px;

  @include mq($bp-small) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq($bp-large, max) {
      flex: 0 0 47.5%;
      margin-right: 5;
      max-width: 47.5%;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }


  @include mq($bp-large) {
    flex: 0 0 30%;
    margin-right: 5%;
    max-width: 30%;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .paragraph {
    margin-bottom: 5px;
  }

  &__content {
    flex: 0 0 auto;
  }

  &__logo {
    align-items: left;
    display: flex;
    flex: 0 0 120px;
    flex-direction: column;
    justify-content: space-around;

    img {
      align-self: flex-start;
      display: inline-block;
      flex: 0 0 auto;
    }
  }
}
