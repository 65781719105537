.articles {
  &--list {

    .article {
      @include clearfix();
      border-bottom: 1px solid $color-grey-lighter;
      margin-bottom: $margin-bottom / 2;
      padding-bottom: $margin-bottom / 2;

      @include mq($bp-small) {
        margin-bottom: $margin-bottom;
        padding-bottom: 20px;
      }

      .lt-ie9 & {
        &::before,
        &::after {
          margin: 0 !important;
          padding: 0 !important;
        }
      }

      &--first {
        border-top: 1px solid $color-grey-lighter;
        padding-top: $margin-bottom / 2;

        @include mq($bp-small) {
          padding-top: 20px;
        }
      }

      &--last {
        margin-bottom: $margin-bottom;
      }

      // ARTIKEL MET AFBEELDING
      &__content {
        &--image {
          @include mq($bp-xsmall) {
            display: inline;
            float: left;
            width: 70%;
          }
        }
      }

      &__image {
        display: block;
        margin: 0 auto;
        max-width: 300px;
        width: 100%;

        &-link {
          display: block;
          margin-bottom: $margin-bottom / 2;
          text-align: center;
          text-decoration: none;

          @include mq($bp-xsmall) {
            display: inline;
            float: left;
            margin-right: 2%;
            max-width: auto;
            width: 28%;
          }
        }
      }

      &__date,
      &__location {
        color: $color-grey;
        display: block;
        margin-bottom: $margin-bottom / 2;
      }
    }
  }

  &--blocks {
    @include font-size(16);

    @include mq($bp-medium) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .article {
      border-bottom: 1px solid $color-grey-lighter;
      color: $color-text;
      display: block;
      margin-bottom: $gutter;
      padding-bottom: $gutter;
      text-decoration: none;

      @include mq($bp-small) {
        display: flex;
        flex: 0 0 48%;
      }

      @include mq($bp-medium) {
        border-bottom: 0;
        padding-bottom: 0;
      }



      &:hover {
        .article__title {
          color: $color-primary;
        }
      }

      &__image {
        @include mq($bp-small) {
          margin-right: 20px;
          padding-top: 10px;
        }

        @include mq($bp-medium) {
          flex: 0 0 120px;
          margin-right: 10px;
        }


        img {
          border-radius: 8px;
          display: block;
          margin: 0 auto;

          @include mq($bp-medium) {
            margin: 0;
          }

        }
      }

      &__title {
        @include font-size(20);
        color: $color-secondary;
        display: block;
        text-align: center;
        text-decoration: none;
        transition: 200ms all ease;

        @include mq($bp-small) {
          text-align: left;
        }

      }

      &__date {
        color: tint($color-grey, 30%);

        &::after {
          content: '|';
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      &__description {
        display: inline;
      }
    }
  }

  &--blog {
    margin-bottom: $gutter;
    margin-top: $gutter;
    position: relative;

    @include mq($bp-small) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @include mq($bp-medium) {
      flex-wrap: nowrap;

      .band--main & {
        flex-wrap: wrap;
      }
    }

    .articles__article {
      margin-bottom: $gutter;

      @include mq($bp-small) {
        flex: 0 0 45%;
      }

      @include mq($bp-medium) {
        flex: 1 1 32%;
        margin-bottom: 0;
        margin-right: 2%;
        max-width: 32%;

        .band--main & {
          margin-bottom: $gutter;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .article {
      &__title {
        color: $color-secondary;
        font-weight: bold;
      }

      &__image {
        text-align: center;

        img {
          max-width: 100%;
        }
      }

      &__link {
        color: $color-secondary;
        text-decoration: none;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  &--jobs {
    .articles {
      &__article {
        color: $color-text;
        text-decoration: none;
      }
    }

    .article {
      display: block;
      margin-bottom: $gutter;

      &__title {
        color: $color-secondary;
        font-size: 120%;
        font-weight: bold;
      }

      .link {
        color: $color-secondary;
      }

      &:hover .link {
        color: $color-primary;
      }
    }

    .band--main & {
      .articles__article {
        border-top: 1px solid $color-grey-lighter;
        padding-top: $gutter;
      }
    }
  }
}


// Generic article styles
.detail-info {
  color: $color-grey-light;
  font-style: italic;
  margin-bottom: $gutter;
}
